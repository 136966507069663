.interactiveTitle {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 3px;
  text-align: center;
  color: #333;
}

.instructions {
  font-size: 1.2em;
  margin-top: 3px;
  margin-bottom: 3px;
  text-align: center;
  color: #333;
}

h1 {
  text-align: center;
}

button {
  font-size: 1em;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #419bfc;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 2px;
}

button:hover {
  background-color: #228afa;
}

.interactiveContainer {
  background-color: rgb(220, 244, 252);
  min-height: calc(
    100vh - 10em
  ); /* Adjusted to give space for the title * instructions */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.categoryBox.over {
  background-color: lightgray;
}

.fileUpload {
  padding-bottom: 20px;
}

.loading {
  margin-top: 20px;
}

.toolbar {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 60px);
  transform: translate(40px, 0);
}

.editDiv {
  position: relative;
  align-items: center;
  font-size: 1.2em;
  transform: translate(-50%, 0%);
}

.editIcon,
.saveIcon,
.shareIcon {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: -200%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120px/2 = 60px) to center the tooltip */

  /* Fade in tooltip - animations (optional) */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.invalidInteractive {
  border: 1px solid red;
  margin: 30px;
}
