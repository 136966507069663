/* InteractiveTemplate.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.4); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensure it's on top including celebrations which use 9999*/
}

.modalContent {
  background-color: rgb(241, 241, 241);
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  overflow: auto;
}

.shareTextArea {
  width: 80%;
  height: 20%;
  min-height: 50px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent resizing */
}

.copyButton,
.closeButton {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copyButton {
  background-color: #4caf50;
  color: white;
}

.copyButton:active {
  background-color: #4caf50;
}

.closeButton {
  background-color: #f44336;
  color: white;
}

.noteBox {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  width: 100%;
  margin: 10px 0;
}

.noteIcon {
  font-size: 24px;
  margin-right: 10px;
  color: #888;
}

.noteContent {
  flex: 1;
}

.note-content a {
  color: #1a73e8;
  text-decoration: none;
}

.note-content a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Make sure the list takes the full width */
}

li {
  padding-left: 8px;
  margin-bottom: 2px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1em;
}

li:hover {
  background-color: #f1f1f1;
}

/* Rate limit warning */
.rateLimitWarning {
  background-color: #ffebee;
  color: #d32f2f;
  border: 1px solid #d32f2f;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.navigationControls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.paginationControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.paginationControls button {
  padding: 5px 5px;
  border: none;
  background-color: #000000;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.paginationControls span {
  margin: 0 10px;
}

@keyframes flash {
  0% {
    background-color: #fff8e1; /* Light yellow */
  }
  100% {
    background-color: white;
  }
}

.flash {
  animation: flash 0.5s ease-in-out;
}
