.error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.textInputContainer {
  background-color: rgb(218, 237, 243);
  min-height: calc(100vh - 8em);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  border-radius: 20px;
  padding: 20px;
}

.interactiveBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  width: 100%;
}

.interactiveTextArea {
  display: flex;
  flex-grow: 1;
  width: 100%;
  font-size: 1.2em;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  resize: none;
}

.imageUploadContainer {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  text-align: left;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 5px;
  width: 100%;
}

.imageTextArea {
  margin: 5px 0px 5px 0px;
}

/* Add to TextInput.css */
.audioRecordContainer {
  margin-top: 20px;
}

.mediaUploadContainer,
.audioRecordContainer {
  margin-bottom: 20px;
}

.mediaUploadContainer img,
.audioRecordContainer audio {
  display: block;
  margin-top: 10px;
}
